import type {NextPage} from 'next';
import {useState} from 'react';

import {setCacheControlHeader} from '../utils/setCacheControlHeader';

const Home: NextPage = () => {
  const [advisor, setAdvisor] = useState('product');

  return (
    <div className="sf-flex sf-h-screen sf-bg-gradient-to-r sf-from-indigo-500 sf-via-purple-500 sf-to-pink-500">
      <div className="sf-w-[375px] sf-m-2 sf-flex sf-flex-col sf-justify-between">
        <h1 className="sf-text-2xl sf-mx-auto sf-p-2 sf-capitalize sf-text-white">
          {advisor} advisor ⭐️🐠
        </h1>
        <div className="sf-space-x-2">
          <button
            type="button"
            className="sf-text-white sf-bg-blue-600 sf-border-2 sf-border-blue-300 hover:sf-border-blue-200 sf-rounded sf-px-2 sf-py-1"
            onClick={() => setAdvisor('package')}
          >
            Package
          </button>
          <button
            type="button"
            className="sf-text-white sf-bg-blue-600 sf-border-2 sf-border-blue-300 hover:sf-border-blue-200 sf-rounded sf-px-2 sf-py-1"
            onClick={() => setAdvisor('product')}
          >
            Product
          </button>
          <button
            type="button"
            className="sf-text-white sf-bg-blue-600 sf-border-2 sf-border-blue-300 hover:sf-border-blue-200 sf-rounded sf-px-2 sf-py-1"
            onClick={() => setAdvisor('plan')}
          >
            Plan
          </button>
        </div>
        <iframe
          title="advisor-mobile"
          src={`/advisor/${advisor}`}
          className="sf-w-[375px] sf-h-[812px]"
        />
      </div>
      <div className="sf-flex-grow">
        <iframe
          title="advisor-desktop"
          src={`/advisor/${advisor}`}
          className="sf-w-full sf-h-full"
        />
      </div>
    </div>
  );
};

export function getServerSideProps({res}: {res: any;}) {
  setCacheControlHeader(res);
  return {
    props: {},
  };
}

export default Home;
